import { defineStore } from "pinia";
import { shallowRef } from "vue";
import {
  getPhysicians,
  getPhysiciansTrialFlags,
  getPhysicianPublicationFlags,
} from "@/services/apiCalls/physician";
import { handleAbort } from "@/services/http";

export const usePhysicianStore = defineStore({
  id: "physician",
  state: () => ({
    physicians: shallowRef([]), // use shallow reactivity to reduce performance overhead in case of many physicians (https://vuejs.org/guide/best-practices/performance.html#reduce-reactivity-overhead-for-large-immutable-structures)
    physicianTrialFlags: {}, // npi -> true if has trials, else false
    physicianPubFlags: {}, // npi -> true if physician has publications, else false
    fetchedPhysicians: false,
    fetchedPhysicianTrialFlags: false,
    fetchedPhysicianPubFlags: false,
    abortController: null,
  }),
  persist: true,
  actions: {
    async getPhysicians() {
      this.physicians = [];
      this.fetchedPhysicians = false;
      try {
        this.physicians = await getPhysicians().then((res) => res.results);
        this.fetchedPhysicians = true;
      } catch (error) {
        if (error.name === "CanceledError") return;
        console.error(error);
      }
    },
    async getPhysicianTrialFlags({ signal }) {
      this.fetchedPhysicianTrialFlags = false;
      try {
        this.physicianTrialFlags = await getPhysiciansTrialFlags(signal);
      } catch (error) {
        if (error.name === "CanceledError") return;
        console.error(error);
      }
      this.fetchedPhysicianTrialFlags = true;
    },
    async getPhysicianPubFlags({ signal }) {
      this.fetchedPhysicianPubFlags = false;
      try {
        this.physicianPubFlags = await getPhysicianPublicationFlags(signal);
      } catch (error) {
        if (error.name === "CanceledError") return;
        console.error(error);
      }
      this.fetchedPhysicianPubFlags = true;
    },
    async getData() {
      this.abortController = handleAbort(this.abortController);
      this.getPhysicianTrialFlags(this.abortController);
      this.getPhysicianPubFlags(this.abortController);
    },
  },
});
