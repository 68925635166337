import { defineStore } from "pinia";
import { useHospitalStore } from "./hospital";
import { usePhysicianStore } from "./physician";
import { getRegionProcedureVolumes } from "@/services/apiCalls/region";

export const useMapStore = defineStore({
  id: "map",
  state: () => ({
    hospitalStore: useHospitalStore(),
    physicianStore: usePhysicianStore(),
    regionProcVolumes: {}, // region id -> procedure set -> volume
    terProcVolumes: {}, // territory id -> procedure set -> volume
    fetchedRegionData: false,
  }),
  persist: true,
  getters: {
    hospitals() {
      return this.hospitalStore.hospitals;
    },
    hospTrialFlags() {
      return this.hospitalStore.hospTrialFlags;
    },
    fetchedHosps() {
      return this.hospitalStore.fetchedHosps;
    },
    fetchedHospTrialFlags() {
      return this.hospitalStore.fetchedHospTrialFlags;
    },
    physicianTrialFlags() {
      return this.physicianStore.physicianTrialFlags;
    },
    physicianPubFlags() {
      return this.physicianStore.physicianPubFlags;
    },
    fetchedPhysicianTrialFlags() {
      return this.physicianStore.fetchedPhysicianTrialFlags;
    },
    fetchedPhysicianPubFlags() {
      return this.physicianStore.fetchedPhysicianPubFlags;
    },
  },
  actions: {
    async getRegionData() {
      this.fetchedRegionData = false;

      const { total_proc_set_volumes, ter_proc_set_volumes } =
        await getRegionProcedureVolumes();

      this.regionProcVolumes = total_proc_set_volumes;
      this.terProcVolumes = ter_proc_set_volumes;
      this.fetchedRegionData = true;
    },
    async forceFetchData() {
      // Forcefully updates the data from the stores. Should be called when we know that the data is stale.
      this.getRegionData();
      this.hospitalStore.getData();
      this.physicianStore.getData();
    },
    async lazyFetchData() {
      // Lazily loads data. Only updates stores that are un-fetched.
      if (!this.fetchedRegionData) {
        this.getRegionData();
      }

      if (!this.fetchedHosps) {
        this.hospitalStore.getData();
      }
    },
  },
});
