import createHttp from "@/services/http";

export async function getPhysicians(
  bounds = null,
  searchTerm = "",
  sortCategory = "",
  limit = null
) {
  const response = await createHttp().post(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/physicians_v2?search=${searchTerm}&sort=${sortCategory}&limit=${
      limit ?? ""
    }`,
    bounds ? { bounds } : {}
  );
  return response.data;
}

export async function getPhysician(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}`
  );
  return response.data;
}

export async function getPhysicianProcedureData(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/procedure_data`
  );
  return response.data;
}

export async function getPhysicianProcedureVolumes(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/procedure_volumes`
  );
  return response.data[npi];
}

export async function getPhysicianDiagnosisVolumes(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/diagnosis_volumes`
  );
  return response.data[npi];
}

export async function getPhysicianSiteOfCareBreakdown(npi) {
  const response = await createHttp().get(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/physician/${npi}/site_of_care_breakdown`
  );
  return response.data;
}

export async function getPhysicianMedicarePrescriptions(npi) {
  const response = await createHttp().get(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/physician/${npi}/medicare_prescriptions`
  );
  return response.data;
}

export async function getPhysicianDiagnoses(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/diagnoses`
  );
  return response.data;
}

export async function getPhysicianReferrals(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/referrals`
  );
  return response.data;
}

export async function getPhysicianReferralsByDiagnosis(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/referrals/diagnoses`
  );
  return response.data;
}

export async function getPhysicianPayments(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/payments`
  );
  return response.data;
}

export async function getSuggestedPhysicians(npi) {
  const path = `${
    import.meta.env.VITE_FLASK_URL
  }/api/physician/suggested?npi=${npi}`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getPhysiciansTrialFlags(signal) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/trial_flags`,
    { signal }
  );
  return response.data;
}

export async function getPhysicianPublicationFlags(signal) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/publication_flags`,
    { signal }
  );
  return response.data;
}

export async function searchPhysicians(path) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/search?${path}`
  );
  return response.data;
}

export async function enrichPhysician(npi) {
  const result = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/enrich`
  );
  return result.data;
}

export async function checkPhysicianEnrichemnt(npi) {
  const result = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/check_if_enriched`
  );
  return result.data;
}

export async function getPhysicianResearchPayments(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/research-payments`
  );
  return response.data;
}

export async function getPhysicianTraining(npi) {
  const response = await createHttp().get(
    import.meta.env.VITE_FLASK_URL +
      `/api/physician/${npi}/education_and_training`
  );
  return response.data;
}

export async function getPhysicianAffiliations(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/affiliations`
  );
  return response.data;
}

export async function getPhysicianConnections(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/connections`
  );
  return response.data;
}

export async function getPhysicianConnectionDetails(npi1, npi2) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/connections/details`,
    {
      params: { npi1, npi2 },
    }
  );
  return response.data;
}

export async function getPhysicianPrimaryHospitals(npis) {
  const response = await createHttp().post(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/primary_hospitals`,
    { npis }
  );
  return response.data;
}

export async function getPhysiciansFilter(query, procModId, signal) {
  const response = await createHttp().post(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/filter${
      procModId ? `?proc_mod=${procModId}` : ""
    }`,
    query,
    { signal }
  );
  return response.data;
}

export async function getDiagnosisCodes() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/icd10_codes`
  );
  return response.data;
}

export async function getSchools() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/medical_schools`
  );
  return response.data;
}

export async function getTrainingLocations() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/training_locations`
  );
  return response.data;
}

export async function getTrainingSpecialties() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physicians/training_specialties`
  );
  return response.data;
}

export async function getPhysicianDiagnosisSets(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/diagnosis_sets`
  );
  return response.data;
}
